<style scoped>
    .img_list {
        display: flex;
        height: 150px;
        line-height: 150px;
        margin-bottom: 20px;
    }

    .img_title {
        color: #606266;
        font-weight: 700;
        width: 80px;
        line-height: 150px;
        text-align: right;
        padding-right: 15px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    .avatar {
        width: 150px;
        height: 150px;
        /*border-radius: 50%;*/
        display: block;
    }

    .top >>> .el-upload--text {
        height: 150px;
        width: 150px;
    }


</style>
<template>
    <div class="container">
        <el-tabs v-model="activeName" :before-leave="changetabs" class="my_tab">
            <el-tab-pane label="基本信息" name="0">
                <el-form ref="ruleForm" :model="userinfo" :rules="rules" label-width="100px" class="demo-ruleForm"
                         size="medium ">
                    <el-form-item label="账号" prop="username">
                        <el-input v-model="userinfo.username" disable></el-input>
                    </el-form-item>
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="userinfo.name"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱" prop="email">
                        <el-input v-model="userinfo.email"></el-input>
                    </el-form-item>
                    <el-form-item style="text-align: center;">
                        <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="个人设置" name="1">
                <el-form :model="userinfo" label-width="100px" class="demo-ruleForm" size="medium ">
                    <el-form-item label="密    码" prop="mobile">
                        <el-input  v-model="userinfo.pwd" style="width: 200px" disabled></el-input>
                        <el-button type="primary" @click="showRsetPwddialog">修改密码
                        </el-button>
                    </el-form-item>
                    <el-form-item label="手机号码" prop="mobile">
                        <el-input v-model="userinfo.mobile" style="width: 200px" disabled></el-input>
                        <el-button type="primary" @click="changeMobile">{{ userinfo.mobile ? '修改手机' : '绑定手机' }}
                        </el-button>
                    </el-form-item>
                    <el-form-item label="微信" prop="mobile">
                        <i v-if="userinfo.wxid" class="Success el-icon-success" style="color: #67C23A">已绑定</i>
                        <el-button
                            type="primary"
                            @click="showcodedialog = true;active = 0">{{userinfo.wxid?'重新绑定':'去绑定' }}
                        </el-button>
                    </el-form-item>

                </el-form>
            </el-tab-pane>
            <el-tab-pane label="其他设置" name="2">
                <div>

                    <div class="img_list ">
                        <div class="img_title">头像</div>
                        <div class="top">
                            <el-upload
                                action="/cloud360-web/user/upUserAvatar"
                                style="height:50px;"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload"
                            >
                                <img v-if="userinfo.avatar" :src="userinfo.avatar" class="avatar"/>
                            </el-upload>
                        </div>
                    </div>

                </div>
            </el-tab-pane>
        </el-tabs>
        <el-dialog title="绑定推送" :visible.sync="showcodedialog" width="800px" @closed="clear_time" >
            <el-steps :active="active" finish-status="success">
                <el-step title="关注公众号"></el-step>
                <el-step title="绑定微信"></el-step>
                <el-step title="完成"></el-step>
            </el-steps>
            <span style="margin: 40px auto;height: 250px;text-align: center;width: 100%;display: block;">
        <img v-if="0 == active" src="" height="250px" style="margin-left: 10px"/>
        <div v-if="1 == active" id="qrcode_box" style="margin-left:285px;height:200px;"></div>
        <div><i v-if="2 == active" class="el-icon-circle-check" height="200" width="600"
                style="font-size: 150px;color: #67C23A;"/></div>
      </span>
            <div style="text-align:center;margin-top: 10px;">
                <el-button  type="primary" @click="showcodedialog=false;clear_time() ">取消</el-button>
                <el-button v-if="active == 1" type="primary" @click="active = 0">上一步</el-button>
                <el-button type="primary" @click="nextstup">{{ ['下一步', '下一步', '完成'][active] }}</el-button>
            </div>
        </el-dialog>

        <el-dialog title="绑定手机号码" width="600px" :visible.sync="showbingdialog" class="datacolect"
                   :close-on-click-modal="false">
            <el-form ref="rulebingdForm" :model="formuserinfo" :rules="rulesFrom">
                <el-form-item label="手机号码" label-width="100px" prop="mobile">
                    <el-input v-model="formuserinfo.mobile" style="width: 350px;"
                              @input="verifyTelephone(formuserinfo.mobile)"></el-input>
                    <el-button type="primary" :disabled="formuserinfo.time > 0" style="width: 105px;"
                               @click="getCode(1)">{{
                        formuserinfo.codeText }}
                    </el-button>
                </el-form-item>
                <el-form-item label="验证码" label-width="100px" prop="code">
                    <el-input v-model="formuserinfo.code"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showbingdialog = false">取 消</el-button>
                <el-button type="primary"
                           :disabled="!formuserinfo.code || (formuserinfo.code && formuserinfo.code.length < 4)"
                           @click="bindingTelephone"
                >确 定
                </el-button
                >
            </div>
        </el-dialog>
        <el-dialog title="修改手机号码" width="600px" :visible.sync="showchangemobiledialog" class="datacolect"
                   :close-on-click-modal="false">
            <el-steps :active="changeMobileActive">
                <el-step></el-step>
                <el-step></el-step>
                <el-step></el-step>
            </el-steps>
            <div></div>
            <el-form v-if="changeMobileActive == 0" ref="rulebingdForm" style="margin-top: 25px;" :model="formuserinfo"
                     :rules="rulesFrom">
                <el-form-item label="旧手机号码" label-width="100px" prop="oldmobile">
                    <el-input v-model="formuserinfo.oldmobile" style="width: 350px;" disabled></el-input>
                    <el-button type="primary" :disabled="formuserinfo.time > 0" style="width: 105px;"
                               @click="getChangeTelephoneCode(0)">{{
                        formuserinfo.codeText
                        }}
                    </el-button>
                </el-form-item>
                <el-form-item label="验证码" label-width="100px" prop="oldcode">
                    <el-input v-model="formuserinfo.oldcode"></el-input>
                </el-form-item>
            </el-form>
            <el-form v-if="changeMobileActive == 1" ref="rulebingdForm" style="margin-top: 25px;" :model="formuserinfo"
                     :rules="rulesFrom">
                <el-form-item label="新手机号码" label-width="100px" prop="mobile">
                    <el-input v-model="formuserinfo.mobile" style="width: 350px;"
                              @input="verifyTelephone(formuserinfo.mobile)"></el-input>
                    <el-button type="primary" :disabled="formuserinfo.time > 0" style="width: 105px;"
                               @click="getChangeTelephoneCode(1)">{{
                        formuserinfo.codeText
                        }}
                    </el-button>
                </el-form-item>
                <el-form-item label="验证码" label-width="100px" prop="code">
                    <el-input v-model="formuserinfo.code"></el-input>
                </el-form-item>
            </el-form>
            <div><i v-if="2 == changeMobileActive" class="el-icon-circle-check" height="200" width="600"
                    style="font-size: 150px;color: #67C23A;"/></div>
            <div slot="footer" class="dialog-footer">
                <el-button v-if="1 == changeMobileActive" type="primary" @click="changeMobileActive = 0">上一步</el-button>
                <el-button
                    type="primary"
                    :disabled="
            changeMobileActive === 0
              ? !(formuserinfo.oldmobile && formuserinfo.oldcode && formuserinfo.oldcode.length >= 4)
              : !(formuserinfo.mobile && formuserinfo.code && formuserinfo.code.length >= 4)
          "
                    @click="NextStep1"
                >{{ ['下一步', '下一步', '完成'][changeMobileActive] }}
                </el-button
                >
                <el-button @click="showchangemobiledialog = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- 用户对话框-->
        <el-dialog title="设置密码" width="600px" :visible.sync="respwddigShow" :close-on-click-modal="false">
            <el-form :model="formuserinfo" :rules="rulesFrom" ref="rulesFrom">
                <el-form-item label="手机号码" label-width="100px" prop="mobile">
                    <el-input v-model="formuserinfo.mobile" style="width: 350px;" disabled></el-input>
                    <el-button type="primary" :disabled="formuserinfo.time > 0" style="width: 105px;"
                               @click="getResetPasswordCode(2)">{{
                        formuserinfo.codeText }}
                    </el-button>
                </el-form-item>
                <el-form-item label="验证码" label-width="100px" prop="code">
                    <el-input v-model="formuserinfo.code"></el-input>
                </el-form-item>

                <el-form-item label="密 码" label-width="100px" prop="pass">
                    <el-input type="password" v-model="formuserinfo.password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" label-width="100px" prop="checkpassword">
                    <el-input type="password" v-model="formuserinfo.checkpassword" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="respwddigShow=false">取 消</el-button>
                <el-button type="primary" @click="resetPassword('rulesFrom')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import USER from '../../api/base/api_user'
    import QRCode from 'qrcodejs2'

    export default {
        data() {
            let validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入密码'))
                } else {
                    if (this.formuserinfo.checkpassword !== '') {
                        this.$refs.rulesFrom.validateField('checkpassword')
                    }
                    callback()
                }
            }
            let validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'))
                } else if (value !== this.formuserinfo.password) {
                    callback(new Error('两次输入密码不一致!'))
                } else {
                    callback()
                }
            }
            return {
                // imageUrl: '',
                activeName: '0',
                timer: undefined,
                showcodedialog: false,

                active: 0,
                changeMobileActive: 0,
                defaultProps: {children: 'subs', label: 'title'},
                // userinfo: this.$store.state.cuttuser,
                shieldingAlarm: window.localStorage.shielding_Alarm ? localStorage.shielding_Alarm : 1,

                userinfo: {},
                showbingdialog: false,
                showchangemobiledialog: false,
                formuserinfo: {codeText: '获取验证码', time: 0},
                menuList: [{name: '监控大屏', url: 'ZTView'}, {name: '首页', url: 'home'}, {name: '冷库地图', url: 'dashboard'}],
                viewList: [],
                isView: false,
                viewId: 0,
                pwdinfo: {oldpwd: undefined, pwd: undefined, checkpwd: undefined},
                truedigShow: false,

                respwddigShow:false,
                rules: {
                    nickname: [{required: true, message: '请输入昵称', trigger: 'blur'}],
                    name: [{required: true, message: '请输入姓名', trigger: 'blur'}],
                    email: [
                        {required: true, message: '请输入邮箱地址', trigger: 'blur'},
                        {type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur,change'}
                    ],
                   // mobile: [{required: true, pattern: /^1\d{10}$/, message: '请输入正确的手机号码！', trigger: 'blur'}],
                },
                rulesFrom: {
                    oldmobile: [{required: true, pattern: /^1\d{10}$/, message: '请输入正确的手机号码！', trigger: 'blur'}],
                    oldcode: [{required: true, min: 4, max: 6, message: '请输入正确的验证码', trigger: 'blur'}],
                    mobile: [{required: true, pattern: /^1\d{10}$/, message: '请输入正确的手机号码！', trigger: 'blur'}],
                    code: [{required: true, min: 4, max: 6, message: '请输入正确的验证码', trigger: 'blur'}],
                    pass: [{required: true,validator: validatePass, trigger: 'blur'}],
                    checkpassword: [{required: true,validator: validatePass2, trigger: 'blur'}]
                }
            }
        },
        watch: {
            '$store.state.cuttuser': function () {
                this.initData();
            },
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                if (this.$store.state.cuttuser.id) {
                    this.initData();
                }
            },
            initData() {
                let userinfo= this.$store.state.cuttuser
                userinfo.pwd='***********'
                if (userinfo.dxLogin)   userinfo.dxLogin = userinfo.dxLogin.toString();
                if (userinfo.astLogin)  userinfo.astLogin = userinfo.astLogin.toString();
                if (userinfo.wxLogin)  userinfo.wxLogin = userinfo.wxLogin.toString();
                delete userinfo['createtime']
                delete userinfo['updattime']
                this.userinfo=userinfo;
            },

            // ===================================================绑定微信==============================================================
            nextstup() {
                if (this.active == 0) {
                    this.initwxcod()
                    this.active = 1
                } else if (this.active == 1) {
                    this.$message.error('请打开微信扫描此二维码，绑定微信！')
                } else if (this.active == 2) {
                    this.clear_time();
                    this.showcodedialog = false
                }
            },

            initwxcod() {
                USER.getUserOauthUrl({type:0,id:this.$store.state.cuttuser.id}).then(res => {
                    this.refcode()
                    this.userinfo.authurl = res.data
                    new QRCode('qrcode_box', {
                        width: 200, // 二维码的宽度
                        height: 200, // 二维码的高度
                        text: res.data, // 二维码地址
                        correctLevel: 3,
                        colorDark: '#000', // 二维码颜色
                        colorLight: '#fff' // 二维码背景颜色
                    })
                })
            },
            refcode() {
                let _this = this
                this.timer = setInterval(function () {
                    // 时钟时间变化
                    USER.getWXAuthStatus({type:0, uid: _this.$store.state.cuttuser.id}).then(res => {
                        if (res.code == 200) {
                            _this.active = 2
                            _this.updateUserinfo(res.data)
                           _this.clear_time();
                        }
                    })
                }, 2000)
            },
            close_wx(done){
                this.clear_time();
               done=true;

            },
            clear_time(){
                if(this.timer) { clearInterval(this.timer); this.timer = undefined ;}
            },

            updateUserinfo(wxid) {
                this.$store.state.cuttuser.wxid = wxid
                this.$store.commit('update_cuttuser', this.$store.state.cuttuser)
            },
            // ===================================================绑定手机号码==============================================================
            verifyTelephone(val) {
                this.formuserinfo.time = /^1[3456789]\d{9}$/.test(val) ? 0 : 60
            },
            // 获取绑定手机的验证码
            getCode() {
                USER.bindingTelephone({type: 0, telephone: this.formuserinfo.mobile}).then(res => {
                    if (res.code === 200) {
                        this.sendmsgsuccess()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            sendmsgsuccess() {
                this.$message.success('验证码已经发送到您的手机，请注意查收')
                this.formuserinfo.time = 60
                let _this = this
                this.timer = setInterval(function () {
                    _this.formuserinfo.time--
                    if (_this.formuserinfo.time <= 0) {
                        _this.clear_time()
                        _this.formuserinfo.codeText = '获取验证码'
                        return
                    }
                    _this.formuserinfo.codeText = _this.formuserinfo.time + '秒'
                }, 1000)
            },


            // 绑定手机号码
            bindingTelephone() {
                let _this = this
                this.$refs['rulebingdForm'].validate(valid => {
                    if (valid) {
                        USER.bindingTelephone({
                            type: 1,
                            telephone: this.formuserinfo.mobile,
                            code: this.formuserinfo.code
                        }).then(res => {
                            if (res.code == 200) {
                                _this.showbingdialog = false
                                _this.$message.success('绑定成功！')
                                _this.$store.state.cuttuser.mobile = _this.formuserinfo.mobile
                                _this.$store.commit('update_cuttuser', _this.$store.state.cuttuser)
                            } else {
                                this.$message.error(res.msg)
                            }
                        })
                    } else {
                        return false
                    }
                })
            },

            // ===================================================修改手机号码==============================================================
            changeMobile() {
                if (this.userinfo.mobile) {
                    // 更改手机号码
                    this.changeMobileActive = 0
                    this.formuserinfo = {
                        oldmobile: this.userinfo.mobile,
                        codeText: '获取验证码',
                        time: 0
                    }
                    this.showchangemobiledialog = true
                } else {
                    // 绑定手机号码
                    this.showbingdialog = true
                }
            },
            getChangeTelephoneCode(type) {
                let telephone
                if (type == 0) {
                    telephone = this.formuserinfo.oldmobile
                } else if (type == 1) {
                    telephone = this.formuserinfo.mobile
                    if (this.formuserinfo.mobile == this.formuserinfo.oldmobile) {
                        this.$message.error('新旧手机号码不能一样！')
                        return
                    }
                }
                let _this = this

                USER.changePhone({telephone: telephone}).then(res => {
                    if (res.code == 200) {
                        this.$message.success('验证码已经发送到您的手机，请注意查收')
                        _this.formuserinfo.time = 60
                        _this.timer = setInterval(function () {
                            _this.formuserinfo.time--
                            if (_this.formuserinfo.time <= 0) {
                                clearInterval(_this.timer)
                                _this.timer = undefined
                                _this.formuserinfo.codeText = '获取验证码'
                                return
                            }
                            _this.formuserinfo.codeText = _this.formuserinfo.time + '秒'
                        }, 1000)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            NextStep1() {
                if (this.changeMobileActive == 0) {
                    this.changeMobileActive = 1
                    this.clear_time();
                    this.formuserinfo.codeText = '获取验证码'
                    this.formuserinfo.time = 0
                } else if (this.changeMobileActive == 1) {
                    let _this = this
                    let mobilecode = this.formuserinfo.oldmobile + '_' + this.formuserinfo.oldcode + '@' + this.formuserinfo.mobile + '_' + this.formuserinfo.code
                    USER.updateUser({
                        id: this.$store.state.cuttuser.id,
                        mobile: this.formuserinfo.mobile,
                        mobilecode: mobilecode
                    }).then(res => {
                        if (res.code == 200) {
                            _this.showchangemobiledialog = false
                            _this.$store.state.cuttuser.mobile = _this.formuserinfo.mobile
                            _this.$store.commit('update_cuttuser', _this.$store.state.cuttuser)
                            _this.$message.success('修改成功！')
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                }
            },

            // ===================================================绑定微信==============================================================


            changetabs(activeName, oldActiveName) {
            },
            submitForm(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        USER.updateUser({
                            username: this.userinfo.username,
                            name: this.userinfo.name,
                            email: this.userinfo.email,
                            id: this.userinfo.id,

                        }).then(res => {
                            if (res.code == 200) {
                                this.$message.success('修改成功！')
                            } else {
                                this.$message.error(res.msg)
                            }
                        })
                    } else {
                        return false
                    }
                })
            },
            resetForm(formName) {
                this.$refs[formName].resetFields()
            },
            handleAvatarSuccess(res, file) {
            },
            changeStatus() {
                USER.updateUser({
                    id: this.userinfo.id,
                    dxLogin: this.userinfo.dxLogin,
                    astLogin: this.userinfo.astLogin,
                    wxLogin: this.userinfo.wxLogin
                }).then(res => {
                })
            },
            changeShieldingAlarm() {
                window.localStorage.shielding_Alarm = this.shieldingAlarm
            },
            changeHomePage() {
                if (this.userinfo.homePage == 'ZTView') {
                    this.isView = true
                } else {
                    this.isView = false
                }
            },
            //====================================================================上传图片
            beforeAvatarUpload(file) {
                if (file.size / 1024 / 1024 > 5) {
                    this.$message.error('上传头像图片大小不能超过 5MB!')
                    return
                }
                let fileFormData = new FormData()
                fileFormData.append('file', file) // filename是键，file是值，就是要传的文件，test.zip是要传的文件名
                USER.upUserAvatar(fileFormData).then(res => {
                    if (res.code == 200) {
                        this.userinfo.avatar = res.data + '?a=' + Math.random() * 100
                        this.$message.success(res.msg)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
                return false
            },
            // ===================================================修改密码==============================================================
            showRsetPwddialog(){
                this.formuserinfo = {
                    mobile: this.userinfo.mobile,
                    codeText: '获取验证码',
                    time: 0
                }
                this.respwddigShow=true;
            },

            getResetPasswordCode(){
                if( !/^1[3456789]\d{9}$/.test(this.formuserinfo.mobile)){
                    this.$message.error('请输入正确的手机号码！')
                    return;
                }
                USER.getcode({ack:(new Date().getDate() * 33 - 11) * 22,token:this.formuserinfo.mobile.substr(7),userName:this.userinfo.username, telephone: this.formuserinfo.mobile}).then(res => {
                    if (res.code == 200) {
                        this.sendmsgsuccess()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            },
            resetPassword(formName){
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        USER.resetpassword( {
                            ack:(new Date().getDate() * 33 - 11) * 22,
                            userName: this.userinfo.username,
                            // email: this.userinfo.email,
                            telephone: this.formuserinfo.mobile,
                            code: this.formuserinfo.code,
                            password: this.formuserinfo.password
                        }).then(res => {
                            if (res.code == 200) {
                                this.respwddigShow=false;
                                this.$message.info('修改成功！')
                            } else {
                                this.$message.error(res.msg)
                            }
                        })
                    } else {
                        return false
                    }
                })
            }
        }
    }
</script>
